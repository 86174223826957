import { Link } from "gatsby";
import React from "react";
import * as styles from "./NavItem.module.scss";
import { CursorContext } from "../../context/CursorContext";

export default function NavItem({ children, to, href, ...props }) {
  const [, setCursor] = React.useContext(CursorContext);

  const toggleCursor = React.useCallback((enabled) => {
    setCursor((enabled) => ({ hover: enabled }));
  });

  if (to != null) {
    return (
      <Link
        to={to}
        onMouseEnter={() => toggleCursor(true)}
        onMouseLeave={() => toggleCursor(false)}
        className={styles.navigationItem}
        {...props}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={() => toggleCursor(true)}
        onMouseLeave={() => toggleCursor(false)}
        className={styles.navigationItem}
        {...props}
      >
        {children}
      </a>
    );
  }
}
