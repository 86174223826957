import { motion, useMotionValue, useAnimation } from "framer-motion";
import * as React from "react";
import * as styles from "./GalleryImage.module.scss";
import { CursorContext } from "../context/CursorContext";
import { isMobile } from "react-device-detect";
import SanityImage from "gatsby-plugin-sanity-image";

export default function GaleryImage({
  src,
  onClick,
  displayWidth,
  displayHeight,
  position,
  alt,
  asset,
}) {
  let [height, setHeight] = React.useState(0);
  let [inity, setInitY] = React.useState(0);
  const ref = React.useRef(null);
  let zindex = useMotionValue(0);
  const controls = useAnimation();
  let x = useMotionValue(0);
  let y = useMotionValue(400);
  let rot = useMotionValue(0);
  const [, setCursor] = React.useContext(CursorContext);

  const toggleCursor = React.useCallback((enabled) => {
    setCursor(() => ({ hover: enabled }));
  });

  const sequence = async () => {
    await controls.start("visible");
    await controls.start("fallFromStart");
    return await controls.start("fallFromAbove");
  };

  React.useEffect(() => {
    let steps = isMobile ? 2 : 12;

    setHeight((displayHeight / 100) * 20);
    zindex.set(getRndInteger(0, 10));
    x.set((position % steps) * (displayWidth / steps));
    let yval = getRndInteger(0, displayHeight);
    y.set(yval);
    setInitY(yval);
    rot.set(0);
    sequence();
  }, []);

  const initialDuration = getRndInteger(30, 60);

  const variants = {
    invisible: {
      opacity: 0,
      transition: {
        ease: "linear",
        duration: getRndInteger(5, 10),
      },
    },
    visible: {
      opacity: 1,
    },
    fallFromStart: {
      y: [null, 1.3 * displayHeight],
      zIndex: [zindex, zindex],
      //      rotate: getRndInteger(0, 1) ? [null, 60] : [null, -60],
      transition: {
        ease: "linear",
        duration: getRndInteger(40, 60) * (1 - inity / displayHeight),
      },
    },
    fallFromAbove: {
      y: [-height, displayHeight + height * 2],
      zIndex: [zindex, zindex],
      //      rotate: [-60, 60],
      transition: {
        ease: "linear",
        duration: getRndInteger(40, 50),
        repeat: Infinity,
        rotate: {
          ease: "linear",
          duration: getRndInteger(60, 90),
          yoyo: Infinity,
        },
      },
    },
  };

  return (
    <motion.div
      onClick={onClick}
      variants={variants}
      initial="invisible"
      onMouseEnter={() => toggleCursor(true)}
      onMouseLeave={() => toggleCursor(false)}
      animate={controls}
      style={{
        x,
        y,
        zIndex: zindex,
        top: -height * 1.5,
      }}
    >
      <SanityImage
        asset={asset}
        {...asset}
        height={300}
        className={styles.GalleryImage}
        alt={alt}
        ref={ref}
      ></SanityImage>
    </motion.div>
  );
}

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRndIntegerHeight(min, max, itemHeight, screenHeight) {
  let percentage = 1 - itemHeight / screenHeight;

  return (Math.floor(Math.random() * (max - min + 1)) + min) * percentage;
}

function getPlusMinusOne() {
  return Math.random() < 0.5 ? -1 : 1;
}
