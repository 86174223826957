import React, { useState, useContext, useCallback, useEffect } from "react";

import { motion, useAnimation } from "framer-motion";
import NavItem from "./navigation/NavItem";
import GalleryImage from "./GaleryImage";
import * as styles from "./layout.module.scss";
import logoFull from "../images/logo/190502_Logo_kurz.svg";
import logo from "../images/logo/190502_Logo.svg";
import { useStaticQuery, graphql } from "gatsby";
import ImageModal from "./ImageModal";
import IconMenu from "./icons/IconMenu";
import { CursorContext } from "../context/CursorContext";
import { isMobile } from "react-device-detect";
import { ImageModalContext } from "../context/ImageModalContext";
import slugify from "../utils/slugify";

export default function Layout({ children, path }) {
  const menuAnimationControls = useAnimation();
  const [currentLogo, setcurrentLogo] = useState(logo);
  const [, setImageModal] = React.useContext(ImageModalContext);
  const data = useStaticQuery(graphql`
    query MyQuery {
      allSanityProject {
        nodes {
          pictures {
            startVisible
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              _id
              url
              id
            }
          }
          name
        }
      }
      allSanityTeamMember {
        nodes {
          pictures {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              _id
              url
              id
            }
          }
        }
      }
    }
  `);
  const [displayWidth, setdisplayWidth] = useState(0);
  const [displayHeight, setdisplayHeight] = useState(0);
  const [images, setImages] = useState([]);
  const [, setCursor] = useContext(CursorContext);

  const toggleCursor = useCallback((enabled) => {
    setCursor(() => ({ hover: enabled }));
  });

  useEffect(() => {
    setdisplayWidth(window.innerWidth);
    setdisplayHeight(window.innerHeight);
    function handleResize() {
      setdisplayWidth(window.innerWidth);
      setdisplayHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    let archArray = data.allSanityProject.nodes
      .filter((node) => node.pictures)
      .map((node) =>
        node.pictures
          .filter((picture) => picture.startVisible)
          .map((picture) => ({
            asset: picture.asset,
            name: node.name,
            imgUrl: picture.asset.url,
            link: "/projects/" + slugify(node.name),
          }))
      )
      .flat();

    let personArray = data.allSanityTeamMember.nodes
      .filter((node) => node.pictures)
      .map((node) => ({
        asset: node.pictures.asset,
        name: "Teammitglied",
        imgUrl: node.pictures.asset.url,
        link: "/buero",
      }))
      .flat();

    archArray = archArray.concat(personArray);

    if (isMobile) {
      setImages(shuffle(archArray).slice(0, 6));
    } else {
      setImages(shuffle(archArray).slice(0, 12));
    }
  }, []);

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  return (
    <div
      className={styles.BackgroundLayer}
      onClick={() => {
        setcurrentLogo(logo);
        menuAnimationControls.start({
          left: "-40rem",
        });
      }}
    >
      <header
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={styles.header}
      >
        <img
          onClick={() => {
            setcurrentLogo(logo);
            menuAnimationControls.start({
              left: "-40rem",
            });
          }}
          className={styles.headerLogo}
          src={currentLogo}
          alt="Etienne Mattern Architektur"
        ></img>
        <motion.div
          transition={{ bounce: 0.25, duration: 0.4, mass: 0 }}
          animate={menuAnimationControls}
          className={styles.headerNav}
        >
          <nav>
            <div className={styles.headerNavTop}>
              <NavItem to="/projects">PROJEKTE</NavItem>
              <NavItem to="/buero">BUERO</NavItem>
              <NavItem to="/jobs">JOBS</NavItem>
              <NavItem to="/contact">KONTAKT</NavItem>
            </div>
            <div className={styles.headerNavBottom}>
              <NavItem href="https://www.instagram.com/emaa_da/">
                INSTAGRAM
              </NavItem>
              <NavItem to="/impressum">IMPRESSUM</NavItem>
              <NavItem to="/datenschutz">DATENSCHUTZ</NavItem>
            </div>
          </nav>
        </motion.div>
        <div className={styles.menuBottom} />
        <div
          className={styles.menuButton}
          onMouseEnter={() => toggleCursor(true)}
          onMouseLeave={() => toggleCursor(false)}
          onClick={(e) => {
            e.stopPropagation();
            setcurrentLogo(logoFull);
            menuAnimationControls.start({
              left: 0,
            });
          }}
        >
          <IconMenu />
        </div>
      </header>
      <motion.main>{children}</motion.main>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {displayWidth > 0
          ? images.map((picture, i) => (
              <GalleryImage
                position={i}
                alt={picture.name}
                displayWidth={displayWidth}
                displayHeight={displayHeight}
                x={getRndInteger(0, displayWidth)}
                zindex={getRndInteger(1, 9)}
                asset={picture.asset}
                src={picture.imgUrl}
                onClick={() => {
                  setcurrentLogo(logo);
                  menuAnimationControls.start({
                    left: "-40rem",
                  });
                  setImageModal({
                    imageUrl: picture.imgUrl,
                    link: picture.link,
                    visible: true,
                    asset: picture.asset,
                  });
                }}
              />
            ))
          : null}
      </div>
    </div>
  );
}

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getPlusMinusOne() {
  return Math.random() < 0.5 ? -1 : 1;
}
